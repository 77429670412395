/* .active{
  color: #ff9900;
} */
.both {
  color: #ff9900;
}
.one {
  color: #ff9900;
}
.none {
  color: #fff;
}
.input {
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
}
.yes {
  background-color: #000;
  color: #fff;
  padding: 8px 18px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}
.banner-img {
  clip-path: polygon(33.79% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%);
  background-color: #afeb47;
}
.card-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (max-width: 490px) {
  .banner-img {
    clip-path: none;
  }
}
