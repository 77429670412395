@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Mulish", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
li {
  list-style: none;
}
input:focus,
textarea:focus {
  outline: none;
}
textarea {
  resize: none;
}
